<template>
  <div class="coupon">
    <van-tabs
      v-model="active"
      @click="tabsClick"
      line-width="20"
      line-height="2"
      v-if="!$route.query.serveId"
    >
      <van-tab title="未使用" name="2" />
      <van-tab title="已使用" name="1" />
      <van-tab title="已过期" name="3" />
    </van-tabs>
    <div class="content-div">
      <template v-if="dataList.length > 0">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div
            :class="['content-li', active == 2 ? '' : 'content-li-2']"
            v-for="item in dataList"
            :key="item.couponId"
          >
            <div class="top-div">
              <p>
                <span class="name">{{ item.couponName }}</span>
                <span class="time"
                  >有效期至{{ item.endTime.split("T")[0] }}</span
                >
              </p>
              <p>
                <span class="worth"
                  >￥<font>{{ item.discountAmount }}</font></span
                >
                <span class="reduction">{{ item.useCondition }}</span>
              </p>
            </div>
            <div class="bottom-div">
              <span
                class="projectBtn"
                @click="
                  (projectContent = item.userServeName), (projectShow = true)
                "
                >适用项目 <van-icon name="arrow"
              /></span>
              <span
                class="status status_green"
                v-if="active == 2"
                @click="useCoupon(item)"
                >立即使用</span
              >
              <span class="status status_gary" v-if="active == 1">已使用</span>
              <span class="status status_gary" v-if="active == 3">已过期</span>
            </div>
          </div>
        </van-list>
      </template>
      <template v-else>
        <div class="nullDataDiv">
          <img src="../../assets/img/error.png" alt="" />
          <span class="text">暂无数据</span>
        </div>
      </template>
    </div>
    <!-- 项目弹窗 -->
    <van-popup
      v-model="projectShow"
      position="bottom"
      :style="{ minHeight: '30%' }"
      round
    >
      <div class="projectPopup">
        <p class="title">适用项目</p>
        <p class="li">1、本优惠券可用于以下服务项目：{{ projectContent }}</p>
        <p class="li">2、本券一次只能使用一张</p>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getLsMyCouponByPage } from "@/api/my";
export default {
  data() {
    return {
      page: 1,
      total: 0,
      loading: false,
      finished: false,
      projectShow: false,
      active: "2",
      dataList: [],
      projectContent: ""
    };
  },
  created() {
    this.getLsMyCouponByPage();
  },
  methods: {
    // 点击使用优惠券
    useCoupon(item) {
      if (this.$route.query.serveId) {
        if(Number(this.$route.query.servePrice) < Number(item.fullAmountMinus)){
          this.$toast("订单满"+item.fullAmountMinus+"元才可用!")
        }else{
          sessionStorage.setItem("couponInfo", JSON.stringify(item));
          this.$router.go(-1);
        }
      } else {
        this.$router.push({ path: "/" });
      }
    },
    // tab切换
    tabsClick() {
      this.page = 1;
      this.dataList = [];
      this.getLsMyCouponByPage();
    },
    // 获取列表数据
    getLsMyCouponByPage() {
      let data = {
        blnStatus: this.active,
        pageIndex: this.page,
        pageSize: 10,
        serveId: this.$route.query.serveId
      };
      getLsMyCouponByPage(JSON.stringify(data)).then(res => {
        this.dataList = this.dataList.concat(res.data.records);
        this.total = res.data.total;
        this.loading = false;
        this.finished = false;
      });
    },
    // 分页
    onLoad() {
      if (this.dataList.length < this.total) {
        this.page++;
        this.getLsMyCouponByPage();
      } else {
        this.finished = true;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.coupon {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .van-tabs {
    position: sticky;
    top: 0;
    background: white;
    border-bottom: solid 1px #e3e3e3;
    padding-bottom: 5px;
  }
  .content-div {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0.4rem;
    .content-li {
      background: rgb(255, 235, 235);
      padding: 0.3rem;
      border-radius: 0.3rem;
      &:not(:last-child) {
        margin-bottom: 0.2rem;
      }
      .top-div,
      .bottom-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .top-div {
        padding: 0.3rem;
        p {
          display: flex;
          flex-direction: column;
          &:last-child {
            white-space: nowrap;
          }
          .name {
            font-size: 0.36rem;
            font-weight: bold;
            margin-bottom: 0.3rem;
          }
          .time {
            color: gray;
          }
          .worth {
            color: red;
            & > font {
              font-size: 0.6rem;
              font-weight: bold;
            }
          }
        }
      }
      .bottom-div {
        padding: 0.3rem 0.3rem 0;
        border-top: dashed 1px rgb(255, 211, 211);
        .status {
          padding: 0.08rem 0.3rem;
          border: solid 1px red;
          color: red;
          border-radius: 0.4rem;
          font-size: 0.28rem;
        }
        .status_green {
          border-color: green;
          color: green;
        }
        .status_grey {
          border-color: grey;
          color: grey;
        }
        .projectBtn {
          font-size: 0.28rem;
          color: gray;
        }
      }
    }
    .content-li-2 {
      background: rgb(242, 242, 242);
      .top-div {
        p {
          .worth {
            color: #000;
          }
          .reduction {
            color: grey;
          }
        }
      }
      .bottom-div {
        border-top: dashed 1px rgb(192, 192, 192);
      }
    }
  }
  .projectPopup {
    padding: 0 0.4rem;
    .title {
      text-align: center;
      font-size: 0.5rem;
      font-weight: bold;
      padding: 0.4rem 0;
    }
    .li {
      margin-top: 10px;
    }
  }
}
</style>
